<template>
  <div class="topublic">
    <div class="description mb-12">
      请您使用企业对公账户打款至“<span class="span1">章易签账户</span
      >”，汇款用途填写“章易签账户充值”
      <!-- ，最小充值金额为<span class="span2">10000元</span> -->
      ，账户信息如下：
    </div>
    <div class="infobox mb-30">
      <div class="title"><span>开户名称：</span>一览数字科技 (江苏) 有限公司</div>
      <div class="title"><span>开户账号：</span>1259 1315 9510 803</div>
      <div class="title"><span>开户银行：</span>招商银行南京珠江路支行</div>
      <div class="title"><span>汇款用途：</span>章易签账户充值</div>
    </div>
    <div class="btitle mb-14">付款方信息</div>
    <div class="w-350 mb-40">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
      class="demo-ruleForm w100" label-position="left">
        <el-form-item label="付款方" prop='name'>
          <el-input v-model="ruleForm.name" :maxlength="config.inputMaxlength" show-word-limit
            placeholder="请输入付款方"/>
        </el-form-item>
        <el-form-item label="付款卡号" prop='code'>
          <el-input v-model="ruleForm.code" :maxlength="config.inputMaxlength" show-word-limit
            placeholder="请输入付款卡号"/>
        </el-form-item>
      </el-form>
    </div>
    <oabutton v-model:loading="loading" width='50' height='30' title="确定" CSStype=2 @buttonclick="submitForm()"/>
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineEmits, watch } from "vue";
const emit = defineEmits(['Emit'])
const loading=ref(false)
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  name:'',//付款方
  code:'',//付款卡号
})
const rules = reactive({//from 表单校验
  name: [
    { required: true, message: '请输入付款方', trigger: 'blur' },
  ],
  code: [
    { required: true, message: '请输入付款卡号', trigger: 'blur' },
  ],
})
// 表单提交
const submitForm = async () => {
  loading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    loading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      emit('Emit',ruleForm)
    } else {
      loading.value = false
    }
  })
}
const setloading=(val)=>{
  loading.value = val
}
defineExpose({
  setloading
});
</script>
<style lang="scss" scoped >
.topublic {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  .description {
    max-width: 350px;
    .span1 {
      color: #8c5234;
    }
    .span2 {
      color: #fa4d4f;
    }
  }
  .infobox {
    width: 350px;
    height: 140px;
    background: url("../../../../assets/img/general/topublicbg.png");
    background-size: 100% 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    padding-left: 31px;
    .title{
      width: 100%;
    }
    .title+.title{
      margin-top: 10px;
    }
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #8c5234;
    span {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }
  .btitle{
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
}
</style>